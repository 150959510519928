@import "./icons.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

// // RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

body {
  * {
    outline: none;
  }
}

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

.leaflet-pane {
  z-index: 1;
}
svg > rect:first-child {
  fill-opacity: 0 !important;
}
.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
  padding-left: 15px;
}
.dz-message {
  text-align: center;
  padding: 30px;
}
// kanban board
.react-kanban-column {
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}
.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.ReactModal__Overlay {
  z-index: 1001 !important;
}
.external-event {
  &:hover {
    cursor: pointer;
  }
}
a:hover {
  cursor: pointer;
}
.rating-container {
  background-color: transparent !important;
}
.input-group-append {
  z-index: 0;
}
.input-color {
  color: $input-color !important;
}
.sketch-picker {
  position: absolute;
  z-index: 1;
}
.rangeslider__fill {
  background-color: $primary !important;
}

.dropdown-menu-end {
  left: auto !important;
  right: 0 !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}
.flatpickr-weekdays {
  background: $primary !important;
}
span.flatpickr-weekday {
  background: $primary !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}
span.flatpickr-weekday {
  color: $white !important;
}
.flatpickr-day.selected {
  background: $primary !important;
}

.bs-select select {
  width: auto !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}
.carousel {
  .control-dots {
    margin: -5px 0px;
  }
  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}

//Two verification Page
.verification {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification {
    input {
      width: 40px !important;
    }
  }
}

@media (max-width: 320px) {
  .verification {
    input {
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
    }
  }
}

//Two verification Page - 2
.verification-2 {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification-2 {
    input {
      width: 40px !important;
      font-size: 14px;
    }
  }
}

@media (max-width: 320px) {
  .verification-2 {
    input {
      width: 30px !important;
      height: 35px !important;
      font-size: 15px !important;
    }
  }
}

//Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;
    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }
  .fc {
    .fc-toolbar {
      > * {
        > * {
          float: none;
        }
      }
    }
  }
  .fc-today-button {
    display: none;
  }
}

//Chat Scrollbar
.ps__rail-y {
  z-index: 999999 !important;
}
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width
    auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up(sm) {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

/* drawer */
.react-drawer-drawer {
  z-index: 9999;
  background: #fff;
}
.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}
.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  transition: all 0.4s;
}
.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  transition: all 0.4s;
}
.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}
.ReactModal__Overlay {
  z-index: 5000 !important;
}
.rangeslider__handle:focus {
  outline: none;
}
// React Table next css
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}
.search-label {
  float: right;
}
.react-drawer-overlay {
  z-index: 9999;
}

// datatable

.react-bs-table-sizePerPage-dropdown {
  .dropdown-menu {
    .dropdown-item {
      a {
        display: block;
      }
    }
  }
}
.rightbar-title .right-bar-toggle {
  background-color: #444c54 !important;
  height: 24px !important;
  width: 24px !important;
  line-height: 24px !important;
  display: block !important;
  color: #eff2f7 !important;
  text-align: center !important;
  border-radius: 50% !important;
}

//rightbar drawer
.css-sourceMap-modules-importLoaders-1-localIdentName-animate__animated___2O131 {
  animation-duration: 0.6s !important;
}
.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass {
  background: rgba(0, 0, 0, 0.15) !important;
}

// .accordion-header {
//   color: #495057 !important;
//   background-color: transparent !important;

//   .active {
//     color: #4d63cf !important;
//     background-color: #eef1fd !important;
//   }
// }
.spinner-loading {
  position: relative;
  left: 50%;
  top: 50%;
}
.modal-content {
  height: 600px;
}
.modal-body {
  overflow: scroll;
}
.fc-event-title {
  display: none;
}
@media print {
  .print-btn {
    display: none;
  }
  .no-print {
    display: none;
  }
  .print-full-height {
    height: 100vh;
  }
  @page {
    size: A4;
    margin: 1in;
  }
}
.va-mid {
  vertical-align: middle;
}

.h-unset {
  height: unset !important;
}

.h-25 {
  height: 25% !important;
}
.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}
.fc-event-main {
  // position: absolute !important;
  top: 0px !important;
  color: white !important;
  text-align: center;
}
.ellipsis {
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.fc-toolbar-chunk button {
  text-transform: capitalize;
}
.fc-timegrid-event-short .fc-event-time::after {
  content: unset !important;
}

.fc-timegrid-event .fc-event-time {
  color: white;
}

.fc-daygrid-block-event, .fc-daygrid-day-frame, .fc-daygrid-event-harness {
  height: 100% !important;
}
.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin: 0px !important;
  min-height: unset;
}
.fc-col-header-cell, .fc .fc-col-header .fc-timegrid-axis {
  height: 40px;
  vertical-align: middle !important;
  background-color: $primary;
  color: white;
}
.fc .fc-col-header .fc-timegrid-axis:after {
  content: 'Time'
}

$number-of-options: 11;

#form-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

form {
  #form-title {
    margin-top: 0;
    font-weight: 400;
    text-align: center;
  }

  #debt-amount-slider {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    position: relative;
    width: 100%;
    height: 50px;
    user-select: none;

    &::before {
      content: " ";
      position: absolute;
      height: 2px;
      width: 100%;
      width: calc(100% * (#{$number-of-options - 1} / #{$number-of-options}));
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #000;
    }

    input,
    label {
      box-sizing: border-box;
      flex: 1;
      user-select: none;
      cursor: pointer;
    }

    label {
      display: inline-block;
      position: relative;
      width: 20%;
      height: 100%;
      user-select: none;

      &::before {
        content: attr(data-debt-amount);
        position: absolute;
        left: 50%;
        padding-top: 10px;
        transform: translate(-50%, 45px);
        font-size: 14px;
        letter-spacing: 0.4px;
        font-weight: 400;
        white-space: nowrap;
        opacity: 0.85;
        transition: all 0.15s ease-in-out;
      }

      &::after {
        content: " ";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
        border: 2px solid #000;
        background: #fff;
        border-radius: 50%;
        pointer-events: none;
        user-select: none;
        z-index: 1;
        cursor: pointer;
        transition: all 0.15s ease-in-out;
      }

      &:hover::after {
        transform: translate(-50%, -50%) scale(1.25);
      }
    }

    input {
      display: none;

      &:checked {
        +label::before {
          font-weight: 800;
          opacity: 1;
        }

        +label::after {
          border-width: 4px;
          transform: translate(-50%, -50%) scale(0.75);
        }

        ~#debt-amount-pos {
          opacity: 1;
        }

        @for $i from 1 through $number-of-options {
          &:nth-child(#{$i * 2 - 1})~#debt-amount-pos {
            left: #{($i * 9.09%) - 4.54%};
          }
        }
      }
    }

    #debt-amount-pos {
      display: block;
      position: absolute;
      top: 50%;
      width: 12px;
      height: 12px;
      background: #000;
      border-radius: 50%;
      transition: all 0.15s ease-in-out;
      transform: translate(-50%, -50%);
      border: 2px solid #fff;
      opacity: 0;
      z-index: 2;
    }
  }

  &:valid {
    #debt-amount-slider {
      input {
        +label::before {
          transform: translate(-50%, 45px) scale(0.9);
          transition: all 0.15s linear;
        }

        &:checked+label::before {
          transform: translate(-50%, 45px) scale(1.1);
          transition: all 0.15s linear;
        }
      }
    }
  }

  &+button {
    display: block;
    position: relative;
    margin: 56px auto 0;
    padding: 10px 20px;
    appearance: none;
    transition: all 0.15s ease-in-out;
    font-family: inherit;
    font-size: 24px;
    font-weight: 600;
    background: #fff;
    border: 2px solid #000;
    border-radius: 8px;
    outline: 0;
    user-select: none;
    cursor: pointer;

    &:hover {
      // transform: scale(1.1);
      background: #000;
      color: #fff;

      &:active {
        transform: scale(0.9);
      }
    }

    &:focus {
      background: #4caf50;
      border-color: #4caf50;
      color: #fff;
      pointer-events: none;

      &::before {
        animation: spin 1s linear infinite;
      }
    }

    &::before {
      display: inline-block;
      width: 0;
      opacity: 0;
      content: "\f3f4";
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      margin-right: 0;
      transform: rotate(0deg);
    }
  }

  &:invalid+button {
    pointer-events: none;
    opacity: 0.25;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
    width: 24px;
    opacity: 1;
    margin-right: 12px;
  }

  to {
    transform: rotate(360deg);
    width: 24px;
    opacity: 1;
    margin-right: 12px;
  }
}

@media (max-width: 767px) {
  .table-responsive .dropdown-menu,
  .table-responsive .dropdown-toggle {
    position: relative !important;
  }
}

@media (max-width: 420px) {
  .mt-c-sm-3 {
    margin-top: 1.5rem !important;
  }
}

@media (min-width: 768px) {
  .table-responsive {
    overflow: visible;
  }
}

.pharmacy-card.card:hover {
  cursor: pointer;
  border: "1px solid #eee";
  box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
}
.mh-300 {
  max-height: 300px;
}
.mh-unset {
  min-height: unset;
  max-height: unset;
  height: unset;
}

.tooltip {
  z-index: 999999 !important;
}